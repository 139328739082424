import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_API_3R_GERENCIAL,
});

api.interceptors.request.use(async config => {
  const token = localStorage.getItem('@3rsistemas-gerencial:token');
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
