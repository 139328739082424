import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import api from '../../../services/api';

import { getIndividualErrors, getErrors } from '../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';

import Confirmation from '../../../components/Confirmation';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
} from '../../../styles/registers';
import Editor from '../../../components/Editor';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  titulo: string;
  erro: string;
  solucao: string;
}

interface ErrorsData {
  [key: string]: string;
}

const ProblemSolutionRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idProblemSolution } = useParams<{ idProblemSolution: string }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    titulo: '',
    erro: '',
    solucao: '',
  });

  const [entityValidation] = useState({
    titulo: Yup.string().required('Preenchimento obrigatório').label('Título'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  const initialEditor = convertFromRaw(
    JSON.parse(
      '{"blocks":[{"key":"dm025","text":" ","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":5,"style":"color-rgb(255,255,255)"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
    ),
  );
  const emptyEditorState = EditorState.createWithContent(initialEditor);

  const [problemEditorState, setProblemEditorState] = useState(
    emptyEditorState,
  );
  const [solutionEditorState, setSolutionEditorState] = useState(
    emptyEditorState,
  );

  useEffect(() => {
    async function loadEntity() {
      if (idProblemSolution !== undefined) {
        const response = await api.get(
          `/problemsSolutions/${idProblemSolution}`,
        );
        const entityManipulated: any = loadForm(response.data, [], [], []);

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);

        if (
          response.data.erro !== null &&
          response.data.erro !== undefined &&
          response.data.erro !== ''
        ) {
          const contentState = convertFromRaw(JSON.parse(response.data.erro));
          const editorStateManipulated = EditorState.createWithContent(
            contentState,
          );
          setProblemEditorState(editorStateManipulated);
        } else {
          setProblemEditorState(EditorState.createEmpty());
        }

        if (
          response.data.solucao !== null &&
          response.data.solucao !== undefined &&
          response.data.solucao !== ''
        ) {
          const contentState = convertFromRaw(
            JSON.parse(response.data.solucao),
          );
          const editorStateManipulated = EditorState.createWithContent(
            contentState,
          );
          setSolutionEditorState(editorStateManipulated);
        } else {
          setSolutionEditorState(EditorState.createEmpty());
        }
      }
    }
    loadEntity();
  }, [idProblemSolution]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);
        entitySave.erro = JSON.stringify(
          convertToRaw(problemEditorState.getCurrentContent()),
        );
        entitySave.solucao = JSON.stringify(
          convertToRaw(solutionEditorState.getCurrentContent()),
        );

        if (idProblemSolution === undefined) {
          await api.post('/problemsSolutions', entitySave);
        } else {
          await api.put(`/problemsSolutions/${idProblemSolution}`, entitySave);
        }
        addToast('Problema/Solução gravado com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
        navigate(-1);
      } catch (err: any) {
        addToast(
          err.response
            ? err.response.data.message
            : 'Problemas ao gravar o problema/solução, tente novamente',
          {
            appearance: 'error',
            autoDismiss: true,
          },
        );
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  function handleProblemEditor(content: any) {
    setProblemEditorState(content);
  }

  async function handleProblemEditorImage(file: any) {
    const data = new FormData();
    data.append('picture', file);
    const response = await api.post('problemsSolutions/upload/problem', data);
    const { filename } = response.data;
    return {
      data: {
        link: `${process.env.REACT_APP_BASEURL_API_3R_GERENCIAL}problems/pictures/${filename}`,
      },
    };
  }

  function handleSolutionEditor(content: any) {
    setSolutionEditorState(content);
  }

  async function handleSolutionEditorImage(file: any) {
    const data = new FormData();
    data.append('picture', file);
    const response = await api.post('problemsSolutions/upload/solution', data);
    const { filename } = response.data;
    return {
      data: {
        link: `${process.env.REACT_APP_BASEURL_API_3R_GERENCIAL}solutions/pictures/${filename}`,
      },
    };
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Problemas/Soluções</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="100%">
              <span>
                {`
                Título *
                ${errors.titulo ? `(${errors.titulo})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.titulo}
                id="titulo"
                autoComplete="off"
                error={!!errors.titulo}
                placeholder="Digite o título"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>DESCRIÇÃO DO ERRO</FormTitle>
          <Editor
            uploadImage={handleProblemEditorImage}
            editorState={problemEditorState}
            onEditorStateChange={e => handleProblemEditor(e)}
          />
        </FormBlock>
        <FormBlock>
          <FormTitle>DESCRIÇÃO DA SOLUÇÃO</FormTitle>
          <Editor
            uploadImage={handleSolutionEditorImage}
            editorState={solutionEditorState}
            onEditorStateChange={e => handleSolutionEditor(e)}
          />
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a53234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ProblemSolutionRegister;
